<template>
    <v-container>
        <v-card class="mb-4">
            <v-card-title class="d-flex align-center py-1">
                <v-text-field v-model="search.quickSearch" @keyup.enter="fetchPrograms()" class="flex-grow-0 mr-2"
                    label="Recherche" color="app-blue" prepend-inner-icon="fas fa-search" outlined hide-details dense />

                <ProgramAutocomplete v-model="search.programs" @input="fetchPrograms()" ref="programAutocomplete"
                    label="Programmes" class="mr-2" flexGrow0 noDynamicSearch multiple />

                <UserAutocomplete v-model="search.intervenants" @input="fetchPrograms()" ref="userAutocomplete"
                    label="Intervenants" class="mr-2" flexGrow0 noDynamicSearch multiple />

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip label v-on="on"> {{ programsTotalCount }} </v-chip>
                    </template>
                    Nombre total de programmes
                </v-tooltip>

                <v-pagination v-model="page" @input="fetchPrograms(true, false)" :length="programsTotalPages"
                    total-visible="5" color="app-blue" />
            </v-card-title>
        </v-card>

        <v-row>
            <v-col cols="2" v-for="program of programs" :key="program.id">
                <ManageProgramIntervenants :programId="program.id" @updated="fetchPrograms()">
                    <template v-slot:activator="{ on }">
                        <v-card v-on="on">
                            <table>
                                <tbody>
                                    <tr>
                                        <td class="px-1 py-1">
                                            <v-avatar size="40">
                                                <v-img :src="getProgramPhotoUrl(program)" />
                                            </v-avatar>
                                        </td>

                                        <td style="width: 100%">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <span class="text-subtitle-2" v-on="on">
                                                        {{ program.title }}
                                                    </span>
                                                </template>
                                                {{ program.title }}
                                            </v-tooltip>
                                        </td>

                                        <td class="px-1">
                                            <v-tooltip v-if="program.intervenants.length > 0" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-chip color="app-green" dark small v-on="on">
                                                        {{ program.intervenants.length }}
                                                    </v-chip>
                                                </template>
                                                {{ program.intervenants.length }} {{ program.intervenants.length |
                                                plural('intervenant', 'intervenants')}}
                                            </v-tooltip>
                                        </td>
                                        <td class="px-1">
                                            <!-- <v-badge :color="getProgramBadgeColor(program)" icon="fas fa-check" inline offset-x="-10" offset-y="-10"></v-badge> -->
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn small icon rounded v-on="on" :color="getProgramBadgeColor(program)" v-if="[-1, 1].includes(program.activated)">
                                                        <v-icon :color="getProgramBadgeColor(program)">fas fa-check-circle</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span v-if="program.activated == -1">Désactivé</span>
                                                <span v-if="program.activated == 1">Activé</span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-card>
                    </template>
                </ManageProgramIntervenants>
            </v-col>
        </v-row>

        <v-card class="pb-2">
            <!-- Pagination -->
            <v-card-title class="d-flex align-center px-2 mt-2 pb-0">
                <v-spacer />

                <v-pagination v-model="page" @input="fetchPrograms(true, false)" :length="programsTotalPages"
                    total-visible="5" color="app-blue" />
            </v-card-title>
        </v-card>
    </v-container>
</template>

<script>
import UserAutocomplete from '../components/widgets/UserAutocomplete.vue';
import ProgramAutocomplete from '../components/widgets/ProgramAutocomplete.vue';
import ManageProgramIntervenants from '../components/admin/intervenants/ManageProgramIntervenants.vue';

export default {
    name: 'Intervenants',

    components: {
        UserAutocomplete,
        ProgramAutocomplete,
        ManageProgramIntervenants
    },

    data: () => ({
        programs: [],
        programsTotalCount: 0,
        programsTotalPages: 1,

        page: 1,
        limit: 42,

        search: {
            quickSearch: null,
            programs: null,
            intervenants: null
        },

        orderBy: 'program:asc',
        defaultOrderBy: 'program:asc',
        sortList: {
            'program:asc': [{ field: 'title', order: 'asc' }],
            'program:desc': [{ field: 'title', order: 'desc' }]
        }
    }),

    methods: {
        async fetchPrograms(push = true, resetPage = true) {
            try {
                this.setLoading(true);

                if (resetPage) {
                    this.page = 1;
                }

                if (push && this.doPush(this.displayQuery)) {
                    this.$router.push({ query: this.displayQuery });
                }

                const { programs, count, totalPages, err } = await this.repos.programs.getPrograms(this.query);
                if (err) {
                    throw new Error(err);
                }
                this.programs = programs;
                this.programsTotalCount = count;
                this.programsTotalPages = totalPages;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement des programmes',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        doPush(query) {
            return (Object.keys(query).length !== Object.keys(this.$route.query).length) || !Object.keys(query).every((key) => query[key] === this.$route.query[key]);
        },

        getProgramBadgeColor(program) {
            let color = 'grey';
            switch (program.activated) {
                case 1:
                    color = 'green';
                    break;
                case -1:
                    color = 'grey';
                    break;
                default:
                    break;
            }
            return color;
        },

        parseQuery(query) {
            this.page = parseInt(query.page) || 1;
            this.orderBy = query.orderBy || this.defaultOrderBy;
            this.search.quickSearch = query.quickSearch || null;
            this.search.programs = query.programs ? query.programs.split(',').map((p) => parseInt(p)) : [];
            this.search.intervenants = query.intervenants ? query.intervenants.split(',').map((p) => parseInt(p)) : [];
        },

        getProgramPhotoUrl(program) {
            const photos = program.photos.filter((d) => d.category != 'Portails uniquement');
            if (Array.isArray(photos) && photos.length > 0) {
                return photos[0].info.url;
            }
            return 'https://cdn.shopify.com/s/files/1/1661/5755/products/property-placeholder_grande.jpg?v=1482778691';
        }
    },

    computed: {
        query() {
            const orderBy = this.sortList[this.orderBy].map((s) => `${s.field}:${s.order}`).join(',');
            const query = {
                page: this.page,
                limit: this.limit,
                include: 'photos,intervenants',
                orderBy
            };

            if (this.search.quickSearch) {
                query.quickSearch = this.search.quickSearch;
            }

            if (this.search.programs) {
                query.programs = this.search.programs.join(',');
            }

            if (this.search.intervenants) {
                query.intervenants = this.search.intervenants.join(',');
            }

            return query;
        },

        displayQuery() {
            const query = JSON.parse(JSON.stringify(this.query));
            delete query.limit;
            delete query.include;
            delete query.debug;
            delete query.archived;

            if (query.page === 1) {
                delete query.page;
            }

            delete query.orderBy;
            if (this.orderBy !== this.defaultOrderBy) {
                query.orderBy = this.orderBy;
            }

            return query;
        }
    },

    created() {
        this.parseQuery(this.$route.query);
        this.fetchPrograms(false, false);
        this.$nextTick(() => {
            this.$refs.programAutocomplete.fetchPrograms({ limit: 10000 });
            this.$refs.userAutocomplete.fetchUsers({ limit: 10000 });
        });
    }
};
</script>

<style lang="scss" scoped>
.card-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>